var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", persistent: "", width: "400" },
      model: {
        value: _vm.dialogCompany,
        callback: function($$v) {
          _vm.dialogCompany = $$v
        },
        expression: "dialogCompany"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-system" }),
              _vm._v(_vm._s(_vm.$t(_vm.titleText)) + " ")
            ])
          ]),
          _c("v-card-text", [
            _c(
              "div",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            disabled: _vm.editMode,
                            label: _vm.$t("code") + " *",
                            placeholder: "" + _vm.$t("Input the code"),
                            clearable: ""
                          },
                          on: { keydown: _vm.clearTabindex },
                          model: {
                            value: _vm.companyObj.code,
                            callback: function($$v) {
                              _vm.$set(_vm.companyObj, "code", $$v)
                            },
                            expression: "companyObj.code"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            label: _vm.$t("max images") + " *",
                            placeholder: "" + _vm.$t("Input the max images"),
                            type: "number",
                            min: 1,
                            max: 7,
                            clearable: ""
                          },
                          on: {
                            keyup: function($event) {
                              $event.preventDefault()
                              return _vm.handleKeyUp($event)
                            },
                            keydown: _vm.clearTabindex
                          },
                          model: {
                            value: _vm.companyObj.max_images,
                            callback: function($$v) {
                              _vm.$set(_vm.companyObj, "max_images", $$v)
                            },
                            expression: "companyObj.max_images"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            label: _vm.$t("description") + " *",
                            placeholder: "" + _vm.$t("Input the description"),
                            maxlength: 50,
                            clearable: ""
                          },
                          on: { keydown: _vm.clearTabindex },
                          model: {
                            value: _vm.companyObj.description,
                            callback: function($$v) {
                              _vm.$set(_vm.companyObj, "description", $$v)
                            },
                            expression: "companyObj.description"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.clickSave }
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.closeModal }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }