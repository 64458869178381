var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-system" }),
        _vm._v(_vm._s(_vm.$t("Dashboard Config")) + " ")
      ]),
      _c("h3", { staticClass: "tit-group mt-10" }, [
        _vm._v(_vm._s(_vm.$t("Alert Setting")))
      ]),
      _c(
        "div",
        { staticClass: "settingBox" },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h4", { staticClass: "tit-section-sub" }, [
                  _vm._v(_vm._s(_vm.$t("Label Status")))
                ]),
                _c("div", [
                  _c("p", { staticClass: "label-txt" }, [
                    _vm._v(_vm._s(_vm.$t("Update Failure")))
                  ]),
                  _c(
                    "div",
                    { staticClass: "innerBox" },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input appendIcon",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          solo: "",
                          suffix: "%",
                          "prepend-icon": "" + _vm.$t("Caution"),
                          "append-outer-icon": "~",
                          type: "number",
                          clearable: ""
                        },
                        on: {
                          keyup: function($event) {
                            $event.preventDefault()
                            return _vm.handleKeyUp($event)
                          }
                        },
                        model: {
                          value: _vm.labelUpdatedCaution,
                          callback: function($$v) {
                            _vm.labelUpdatedCaution = $$v
                          },
                          expression: "labelUpdatedCaution"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "form-input appendIcon",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          solo: "",
                          suffix: "%",
                          "prepend-icon": "" + _vm.$t("Danger"),
                          "append-outer-icon": "~",
                          type: "number",
                          clearable: ""
                        },
                        on: {
                          keyup: function($event) {
                            $event.preventDefault()
                            return _vm.handleKeyUp($event)
                          }
                        },
                        model: {
                          value: _vm.labelUpdatedDanger,
                          callback: function($$v) {
                            _vm.labelUpdatedDanger = $$v
                          },
                          expression: "labelUpdatedDanger"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c("v-col", [
                _c("h4", { staticClass: "tit-section-sub" }, [
                  _vm._v(_vm._s(_vm.$t("Gateway Status")))
                ]),
                _c("div", [
                  _c("p", { staticClass: "label-txt" }, [
                    _vm._v(_vm._s(_vm.$t("Offline")))
                  ]),
                  _c(
                    "div",
                    { staticClass: "innerBox" },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input appendIcon",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          solo: "",
                          suffix: "%",
                          "prepend-icon": "" + _vm.$t("Caution"),
                          "append-outer-icon": "~",
                          type: "number",
                          clearable: ""
                        },
                        on: {
                          keyup: function($event) {
                            $event.preventDefault()
                            return _vm.handleKeyUp($event)
                          }
                        },
                        model: {
                          value: _vm.gwOfflineCaution,
                          callback: function($$v) {
                            _vm.gwOfflineCaution = $$v
                          },
                          expression: "gwOfflineCaution"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "form-input appendIcon",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          solo: "",
                          suffix: "%",
                          "prepend-icon": "" + _vm.$t("Danger"),
                          "append-outer-icon": "~",
                          type: "number",
                          clearable: ""
                        },
                        on: {
                          keyup: function($event) {
                            $event.preventDefault()
                            return _vm.handleKeyUp($event)
                          }
                        },
                        model: {
                          value: _vm.gwOfflineDanger,
                          callback: function($$v) {
                            _vm.gwOfflineDanger = $$v
                          },
                          expression: "gwOfflineDanger"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("h4", { staticClass: "tit-section-sub" }, [
                    _vm._v(_vm._s(_vm.$t("Exception Status")))
                  ]),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("div", [
                          _c("p", { staticClass: "label-txt" }, [
                            _vm._v(_vm._s(_vm.$t("Label Offline")))
                          ]),
                          _c(
                            "div",
                            { staticClass: "innerBox" },
                            [
                              _c("v-text-field", {
                                staticClass: "form-input appendIcon",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  solo: "",
                                  suffix: "%",
                                  "prepend-icon": "" + _vm.$t("Caution"),
                                  "append-outer-icon": "~",
                                  type: "number",
                                  clearable: ""
                                },
                                on: {
                                  keyup: function($event) {
                                    $event.preventDefault()
                                    return _vm.handleKeyUp($event)
                                  }
                                },
                                model: {
                                  value: _vm.labelOfflineCaution,
                                  callback: function($$v) {
                                    _vm.labelOfflineCaution = $$v
                                  },
                                  expression: "labelOfflineCaution"
                                }
                              }),
                              _c("v-text-field", {
                                staticClass: "form-input appendIcon",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  solo: "",
                                  suffix: "%",
                                  "prepend-icon": "" + _vm.$t("Danger"),
                                  "append-outer-icon": "~",
                                  type: "number",
                                  clearable: ""
                                },
                                on: {
                                  keyup: function($event) {
                                    $event.preventDefault()
                                    return _vm.handleKeyUp($event)
                                  }
                                },
                                model: {
                                  value: _vm.labelOfflineDanger,
                                  callback: function($$v) {
                                    _vm.labelOfflineDanger = $$v
                                  },
                                  expression: "labelOfflineDanger"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]),
                      _c("v-col", [
                        _c("div", [
                          _c("p", { staticClass: "label-txt" }, [
                            _vm._v(_vm._s(_vm.$t("Low Battery")))
                          ]),
                          _c(
                            "div",
                            { staticClass: "innerBox" },
                            [
                              _c("v-text-field", {
                                staticClass: "form-input appendIcon",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  solo: "",
                                  suffix: "%",
                                  "prepend-icon": "" + _vm.$t("Caution"),
                                  "append-outer-icon": "~",
                                  type: "number",
                                  clearable: ""
                                },
                                on: {
                                  keyup: function($event) {
                                    $event.preventDefault()
                                    return _vm.handleKeyUp($event)
                                  }
                                },
                                model: {
                                  value: _vm.labelLowBatteryCaution,
                                  callback: function($$v) {
                                    _vm.labelLowBatteryCaution = $$v
                                  },
                                  expression: "labelLowBatteryCaution"
                                }
                              }),
                              _c("v-text-field", {
                                staticClass: "form-input appendIcon",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  solo: "",
                                  suffix: "%",
                                  "prepend-icon": "" + _vm.$t("Danger"),
                                  "append-outer-icon": "~",
                                  type: "number",
                                  clearable: ""
                                },
                                on: {
                                  keyup: function($event) {
                                    $event.preventDefault()
                                    return _vm.handleKeyUp($event)
                                  }
                                },
                                model: {
                                  value: _vm.labelLowBatteryDanger,
                                  callback: function($$v) {
                                    _vm.labelLowBatteryDanger = $$v
                                  },
                                  expression: "labelLowBatteryDanger"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "pt-10" },
                    [
                      _c("v-col", [
                        _c("div", [
                          _c("p", { staticClass: "label-txt" }, [
                            _vm._v(_vm._s(_vm.$t("Bad Signal")))
                          ]),
                          _c(
                            "div",
                            { staticClass: "innerBox" },
                            [
                              _c("v-text-field", {
                                staticClass: "form-input appendIcon",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  solo: "",
                                  suffix: "%",
                                  "prepend-icon": "" + _vm.$t("Caution"),
                                  "append-outer-icon": "~",
                                  type: "number",
                                  clearable: ""
                                },
                                on: {
                                  keyup: function($event) {
                                    $event.preventDefault()
                                    return _vm.handleKeyUp($event)
                                  }
                                },
                                model: {
                                  value: _vm.labelSignalCaution,
                                  callback: function($$v) {
                                    _vm.labelSignalCaution = $$v
                                  },
                                  expression: "labelSignalCaution"
                                }
                              }),
                              _c("v-text-field", {
                                staticClass: "form-input appendIcon",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  solo: "",
                                  suffix: "%",
                                  "prepend-icon": "" + _vm.$t("Danger"),
                                  "append-outer-icon": "~",
                                  type: "number",
                                  clearable: ""
                                },
                                on: {
                                  keyup: function($event) {
                                    $event.preventDefault()
                                    return _vm.handleKeyUp($event)
                                  }
                                },
                                model: {
                                  value: _vm.labelSignalDanger,
                                  callback: function($$v) {
                                    _vm.labelSignalDanger = $$v
                                  },
                                  expression: "labelSignalDanger"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]),
                      _c("v-col", [
                        _c("div", [
                          _c("p", { staticClass: "label-txt" }, [
                            _vm._v(_vm._s(_vm.$t("Misused")))
                          ]),
                          _c(
                            "div",
                            { staticClass: "innerBox" },
                            [
                              _c("v-text-field", {
                                staticClass: "form-input appendIcon",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  solo: "",
                                  suffix: "%",
                                  "prepend-icon": "" + _vm.$t("Caution"),
                                  "append-outer-icon": "~",
                                  type: "number",
                                  clearable: ""
                                },
                                on: {
                                  keyup: function($event) {
                                    $event.preventDefault()
                                    return _vm.handleKeyUp($event)
                                  }
                                },
                                model: {
                                  value: _vm.labelMisusedCaution,
                                  callback: function($$v) {
                                    _vm.labelMisusedCaution = $$v
                                  },
                                  expression: "labelMisusedCaution"
                                }
                              }),
                              _c("v-text-field", {
                                staticClass: "form-input appendIcon",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  solo: "",
                                  suffix: "%",
                                  "prepend-icon": "" + _vm.$t("Danger"),
                                  "append-outer-icon": "~",
                                  type: "number",
                                  clearable: ""
                                },
                                on: {
                                  keyup: function($event) {
                                    $event.preventDefault()
                                    return _vm.handleKeyUp($event)
                                  }
                                },
                                model: {
                                  value: _vm.labelMisusedDanger,
                                  callback: function($$v) {
                                    _vm.labelMisusedDanger = $$v
                                  },
                                  expression: "labelMisusedDanger"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-btn",
            {
              staticClass: "btn t-bottom",
              attrs: { text: "", disabled: _vm.btnDisabledAlertSetting },
              on: { click: _vm.clickSave }
            },
            [_vm._v(_vm._s(_vm.$t("Save")))]
          ),
          _c(
            "v-btn",
            {
              staticClass: "btn t-bottom ml-2",
              attrs: { text: "", disabled: _vm.btnDisabledAlertSetting },
              on: { click: _vm.clickReset }
            },
            [_vm._v(_vm._s(_vm.$t("Reset")))]
          )
        ],
        1
      ),
      _c("h3", { staticClass: "tit-group mt-10" }, [
        _vm._v(_vm._s(_vm.$t("Alive Interval")))
      ]),
      _c("v-text-field", {
        staticClass: "form-input",
        staticStyle: { width: "200px" },
        attrs: {
          outlined: "",
          dense: "",
          "hide-details": "",
          solo: "",
          min: "0",
          type: "number"
        },
        on: {
          keyup: function($event) {
            $event.preventDefault()
            return _vm.handleKeyUp($event)
          }
        },
        model: {
          value: _vm.aliveInterval,
          callback: function($$v) {
            _vm.aliveInterval = $$v
          },
          expression: "aliveInterval"
        }
      }),
      _c(
        "v-btn",
        {
          staticClass: "btn-s mt-2",
          attrs: { text: "" },
          on: { click: _vm.clickIntervalSave }
        },
        [_vm._v(_vm._s(_vm.$t("Save")))]
      ),
      _c(
        "div",
        { class: { hidden: this.SingleUser } },
        [
          _c("h3", { staticClass: "tit-group mt-10" }, [
            _vm._v(_vm._s(_vm.$t("Area Group Management")))
          ]),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "3" } }, [
                _c(
                  "div",
                  { staticClass: "areaGroup" },
                  [
                    _c("h4", { staticClass: "tit" }, [
                      _vm._v(_vm._s(_vm.$t("Company")))
                    ]),
                    _c(
                      "v-list-item-group",
                      {
                        model: {
                          value: _vm.selectedCompany,
                          callback: function($$v) {
                            _vm.selectedCompany = $$v
                          },
                          expression: "selectedCompany"
                        }
                      },
                      [
                        _c("v-virtual-scroll", {
                          staticClass: "scroll-selector",
                          attrs: {
                            items: _vm.companyList,
                            "item-height": 50,
                            height: "250"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-list-item",
                                    { key: item, attrs: { value: item } },
                                    [
                                      _c("v-list-item-title", {
                                        domProps: { textContent: _vm._s(item) }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "area-btn-group" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn",
                        attrs: {
                          text: "",
                          disabled: _vm.btnController.isSolumAdmin
                        },
                        on: {
                          click: function($event) {
                            return _vm.clickAreaBtn("new", "company")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("New")))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn ml-1",
                        attrs: {
                          text: "",
                          disabled: _vm.btnController.isSolumAdmin
                        },
                        on: {
                          click: function($event) {
                            return _vm.clickAreaBtn("edit", "company")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Edit")))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn ml-1",
                        attrs: { text: "", disabled: true },
                        on: {
                          click: function($event) {
                            return _vm.deleteArea("company")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Delete")))]
                    )
                  ],
                  1
                )
              ]),
              _c("v-col", { attrs: { cols: "3" } }, [
                _c(
                  "div",
                  { staticClass: "areaGroup" },
                  [
                    _c("h4", { staticClass: "tit" }, [
                      _vm._v(_vm._s(_vm.$t("Country")))
                    ]),
                    _c(
                      "v-list-item-group",
                      {
                        model: {
                          value: _vm.selectedCountry,
                          callback: function($$v) {
                            _vm.selectedCountry = $$v
                          },
                          expression: "selectedCountry"
                        }
                      },
                      [
                        _c("v-virtual-scroll", {
                          staticClass: "scroll-selector",
                          attrs: {
                            items: _vm.countryList,
                            "item-height": 50,
                            height: "250"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-list-item",
                                    {
                                      key: item,
                                      attrs: { value: item },
                                      on: { click: _vm.clickCountry }
                                    },
                                    [
                                      _c("v-list-item-title", {
                                        domProps: { textContent: _vm._s(item) }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "area-btn-group" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn",
                        attrs: {
                          text: "",
                          disabled: _vm.btnController.company
                        },
                        on: {
                          click: function($event) {
                            return _vm.clickAreaBtn("new", "country")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("New")))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn ml-1",
                        attrs: {
                          text: "",
                          disabled: _vm.btnController.country
                        },
                        on: {
                          click: function($event) {
                            return _vm.clickAreaBtn("edit", "country")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Edit")))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn ml-1",
                        attrs: {
                          text: "",
                          disabled: _vm.btnController.country
                        },
                        on: {
                          click: function($event) {
                            return _vm.deleteArea("country")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Delete")))]
                    )
                  ],
                  1
                )
              ]),
              _c("v-col", { attrs: { cols: "3" } }, [
                _c(
                  "div",
                  { staticClass: "areaGroup" },
                  [
                    _c("h4", { staticClass: "tit" }, [
                      _vm._v(_vm._s(_vm.$t("Region")))
                    ]),
                    _c(
                      "v-list-item-group",
                      {
                        model: {
                          value: _vm.selectedRegion,
                          callback: function($$v) {
                            _vm.selectedRegion = $$v
                          },
                          expression: "selectedRegion"
                        }
                      },
                      [
                        _c("v-virtual-scroll", {
                          staticClass: "scroll-selector",
                          attrs: {
                            items: _vm.regionList,
                            "item-height": 50,
                            height: "250"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-list-item",
                                    {
                                      key: item,
                                      attrs: { value: item },
                                      on: { click: _vm.clickRegion }
                                    },
                                    [
                                      _c("v-list-item-title", {
                                        domProps: { textContent: _vm._s(item) }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "area-btn-group" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn",
                        attrs: {
                          text: "",
                          disabled: _vm.btnController.country
                        },
                        on: {
                          click: function($event) {
                            return _vm.clickAreaBtn("new", "region")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("New")))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn ml-1",
                        attrs: { text: "", disabled: _vm.btnController.region },
                        on: {
                          click: function($event) {
                            return _vm.clickAreaBtn("edit", "region")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Edit")))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn ml-1",
                        attrs: { text: "", disabled: _vm.btnController.region },
                        on: {
                          click: function($event) {
                            return _vm.deleteArea("region")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Delete")))]
                    )
                  ],
                  1
                )
              ]),
              _c("v-col", { attrs: { cols: "3" } }, [
                _c(
                  "div",
                  { staticClass: "areaGroup" },
                  [
                    _c("h4", { staticClass: "tit" }, [
                      _vm._v(_vm._s(_vm.$t("City")))
                    ]),
                    _c(
                      "v-list-item-group",
                      {
                        model: {
                          value: _vm.selectedCity,
                          callback: function($$v) {
                            _vm.selectedCity = $$v
                          },
                          expression: "selectedCity"
                        }
                      },
                      [
                        _c("v-virtual-scroll", {
                          staticClass: "scroll-selector",
                          attrs: {
                            "v-model": _vm.cityList,
                            items: _vm.cityList,
                            "item-height": 50,
                            height: "250"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-list-item",
                                    { key: item, attrs: { value: item } },
                                    [
                                      _c("v-list-item-title", {
                                        domProps: { textContent: _vm._s(item) }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "area-btn-group" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn",
                        attrs: { text: "", disabled: _vm.btnController.region },
                        on: {
                          click: function($event) {
                            return _vm.clickAreaBtn("new", "city")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("New")))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn ml-1",
                        attrs: { text: "", disabled: _vm.btnController.city },
                        on: {
                          click: function($event) {
                            return _vm.clickAreaBtn("edit", "city")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Edit")))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn ml-1",
                        attrs: { text: "", disabled: _vm.btnController.city },
                        on: {
                          click: function($event) {
                            return _vm.deleteArea("city")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Delete")))]
                    )
                  ],
                  1
                )
              ]),
              _c("areaSettingModal", {
                attrs: {
                  dialog: _vm.dialogArea,
                  action: _vm.actionAreaModal,
                  category: _vm.categoryAreaModal,
                  item: _vm.valueAreaModal
                },
                on: {
                  saveArea: _vm.saveArea,
                  closeAreaModal: _vm.closeAreaSettingModal
                }
              }),
              _c("companySettingModal", {
                attrs: {
                  dialogCompany: _vm.dialogCompany,
                  action: _vm.actionAreaModal,
                  category: _vm.categoryAreaModal,
                  item: _vm.valueAreaModal
                },
                on: {
                  saveCompany: _vm.saveCompany,
                  closeCompanyModal: _vm.closeCompanySettingModal
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticStyle: { display: "none" } }, [
        _c("h3", { staticClass: "tit-group mt-10" }, [
          _vm._v("Defalt Password Setting")
        ]),
        _c(
          "div",
          { staticClass: "settingBox" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "4" } },
                  [
                    _c("v-text-field", {
                      staticClass: "form-input",
                      attrs: {
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        label: "Password",
                        type: "password"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "4" } },
                  [
                    _c("v-text-field", {
                      staticClass: "form-input",
                      attrs: {
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        label: "Confirm Password",
                        type: "password"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c("v-btn", { staticClass: "btn t-bottom", attrs: { text: "" } }, [
              _vm._v(_vm._s(_vm.$t("Save")))
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }